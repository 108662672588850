
import Vue from 'vue';
import Toolbar from './Toolbar.vue';
import LoggedIn from './LoggedIn.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import Store from '@/store';
import { LoggingService } from '@/services/logging';
import LocationWarning from './LocationWarning.vue';
export default Vue.extend({
  name: 'Location',
  data() {
    return {
      displayAlert: false,
      continueDialog: false,
      loading: false,
    };
  },
  components: {
    Toolbar,
    LoggedIn,
    ConfirmDialog,
    LocationWarning,
  },
  methods: {
    next() {
      this.$router.push({ path: '/start' });
    },
    proceedWithoutLocation() {
      (this.$refs.confirm as any).open(
        'Proceed Anyway?',
        'Are you sure you want to proceed without including your location?',
        { color: 'primary' }
      );
    },

    async updateLocation() {
      let geoSuccess = (position: any) => {
        const location = [position.coords.longitude, position.coords.latitude];
        LoggingService.Log(`location captured ${location}`);
        Store.commit('updateLocation', location);
        this.next();
      };
      let geoError = (error: any) => {
        LoggingService.Log('Error occurred. Error code: ' + error.code);
        Store.commit('updateLocation', null);
        // error.code can be:
        //   0: unknown error
        //   1: permission denied
        //   2: position unavailable (error response from location provider)
        //   3: timed out
        this.displayAlert = true;
        this.loading = false;
      };
      const fifteenSeconds = 15_000;
      this.loading = true;
      let options = {
        enableHighAccuracy: true,
        timeout: fifteenSeconds,
      };
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, options);
    },
  },
});
